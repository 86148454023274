import React, { useEffect } from 'react';
import type { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { PerfectBotCookies, urlStructure } from '@/constants';
import Loader from '@/components/Loader';
import { eraseCookie, setCookie } from '@/utils/cookies';


interface LoginProps {
  query: {
    redirect?: string,
  },
}

const Login = ({ query }: LoginProps) => {
  const router = useRouter();
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    void router.prefetch(urlStructure.app);
  });

  if (isLoading) {
    return (
      <Box sx={{ height: '100vh' }}>
        <Loader centered size={60} />
      </Box>
    );
  }

  if (error) {
    throw error;
  }

  if (isAuthenticated) {
    eraseCookie(PerfectBotCookies.REDIRECT_URL);
    void router.push(urlStructure.app);
  } else {
    if (query.redirect && query.redirect !== urlStructure.app) {
      setCookie(PerfectBotCookies.REDIRECT_URL, query.redirect, 30);
    } else {
      eraseCookie(PerfectBotCookies.REDIRECT_URL);
    }
    eraseCookie(PerfectBotCookies.GORGIAS_APP_ID_OVERRIDE);
    void loginWithRedirect();
  }
  return null;
};

export async function getServerSideProps({ locale, query }: GetServerSidePropsContext) {
  return {
    props: {
      query,
      ...(await serverSideTranslations(locale!, ['common', 'translation', 'error'])),
    },
  };
}

export default Login;
